import logo from "./img/logo.png";
import intro from "./img/intro.png";
import about from "./img/about.png";
//import tokenomics from "./img/tokenomics.png";

import "./App.css";

import AOS from "aos";
import "aos/dist/aos.css";
import { useEffect } from "react";

function App() {
  useEffect(() => {
    AOS.init({
      duration: 800,
    });
    AOS.refresh();
  }, []);
  return (
    <div className="App">
      <header className="py-5">
        <div className="container mx-auto px-2 lg:px-5 flex flex-col lg:flex-row gap-5 justify-between items-center">
          <div className="w-20">
            <img src={logo} alt="" />
          </div>
          <ul className="flex gap-5 lg:gap-10">
            <li>
              <a href="/" className="transition-all hover:opacity-75">
                HOME
              </a>
            </li>
            <li>
              <a href="#about" className="transition-all hover:opacity-75">
                ABOUT
              </a>
            </li>
            <li>
              <a href="#tokenomics" className="transition-all hover:opacity-75">
                TOKENOMICS
              </a>
            </li>
            <li>
              <a className="transition-all hover:opacity-75">CHART</a>
            </li>
          </ul>
          <a className="py-3 px-8 bg-[#E76BA1] text-white rounded-3xl  box-shadow-1 transition-all hover:opacity-75">
            BUY NOW
          </a>
        </div>
      </header>
      <main>
        <section className="py-20">
          <div className="container mx-auto px-2 lg:px-5 text-center">
            <div className="max-w-xs lg:max-w-md mx-auto">
              <img src={intro} alt="" />
            </div>
            <div className="flex items-center justify-center gap-5 lg:gap-10 my-10">
              <a>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="63"
                  height="63"
                  viewBox="0 0 60 63"
                  fill="none"
                >
                  <path
                    d="M30.0683 0H29.9317C13.4009 0 0 13.9768 0 31.218V31.3605C0 48.6017 13.4009 62.5784 29.9317 62.5784H30.0683C46.5991 62.5784 60 48.6017 60 31.3605V31.218C60 13.9768 46.5991 0 30.0683 0Z"
                    fill="white"
                  />
                  <path
                    d="M39.2841 12.7314H20.7184C15.5893 12.7314 11.4166 17.0834 11.4166 22.4329V40.1477C11.4166 45.4973 15.5893 49.8492 20.7184 49.8492H39.2841C44.4132 49.8492 48.5858 45.4973 48.5858 40.1477V22.4329C48.5858 17.0834 44.4132 12.7314 39.2841 12.7314ZM14.698 22.4329C14.698 18.9711 17.3992 16.1538 20.7184 16.1538H39.2841C42.6033 16.1538 45.3044 18.9711 45.3044 22.4329V40.1477C45.3044 43.6096 42.6033 46.4268 39.2841 46.4268H20.7184C17.3992 46.4268 14.698 43.6096 14.698 40.1477V22.4329Z"
                    fill="black"
                  />
                  <path
                    d="M30.0013 40.3122C34.771 40.3122 38.6535 36.265 38.6535 31.2882C38.6535 26.3114 34.7731 22.2642 30.0013 22.2642C25.2296 22.2642 21.3491 26.3114 21.3491 31.2882C21.3491 36.265 25.2296 40.3122 30.0013 40.3122ZM30.0013 25.6887C32.9632 25.6887 35.3722 28.2012 35.3722 31.2904C35.3722 34.3795 32.9632 36.892 30.0013 36.892C27.0395 36.892 24.6305 34.3795 24.6305 31.2904C24.6305 28.2012 27.0395 25.6887 30.0013 25.6887Z"
                    fill="black"
                  />
                  <path
                    d="M39.4545 23.7221C40.7389 23.7221 41.7857 22.6324 41.7857 21.2907C41.7857 19.9489 40.741 18.8593 39.4545 18.8593C38.168 18.8593 37.1233 19.9489 37.1233 21.2907C37.1233 22.6324 38.168 23.7221 39.4545 23.7221Z"
                    fill="black"
                  />
                </svg>
              </a>
              <a
                href="https://twitter.com/CottonBud_SOL"
                target="_blank"
                rel="noopener noreferrer"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="63"
                  height="63"
                  viewBox="0 0 60 63"
                  fill="none"
                >
                  <path
                    d="M59.9958 31.2892C59.9958 47.0917 48.7647 60.1559 34.1746 62.2759C32.8104 62.4732 31.4147 62.5762 29.9979 62.5762C28.3625 62.5762 26.7566 62.4403 25.1927 62.1772C10.9095 59.7788 0 46.8615 0 31.2892C0 14.0091 13.4319 0 30 0C46.5681 0 60 14.0091 60 31.2892H59.9958Z"
                    fill="white"
                  />
                  <path
                    d="M12.1664 13.8008L26.0019 33.0935L12.0802 48.7798H15.2143L27.4039 35.0469L37.2519 48.7798H47.9154L33.3022 28.4019L46.2611 13.8008H43.127L31.9022 26.4485L22.832 13.8008H12.1685H12.1664ZM16.774 16.208H21.6717L43.3036 46.3726H38.4059L16.774 16.208Z"
                    fill="black"
                  />
                </svg>
              </a>
              <a
                href="https://t.me/CottonBud_Sol"
                target="_blank"
                rel="noopener noreferrer"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="63"
                  height="63"
                  viewBox="0 0 60 63"
                  fill="none"
                >
                  <path
                    d="M30.0683 0H29.9317C13.4009 0 0 13.9768 0 31.218V31.3605C0 48.6017 13.4009 62.5784 29.9317 62.5784H30.0683C46.5991 62.5784 60 48.6017 60 31.3605V31.218C60 13.9768 46.5991 0 30.0683 0Z"
                    fill="white"
                  />
                  <path
                    d="M11.3638 30.2226C11.4479 30.1788 11.532 30.1371 11.614 30.0977C13.0392 29.4093 14.4833 28.7647 15.9254 28.1201C16.0031 28.1201 16.1334 28.0258 16.207 27.9951C16.3184 27.9447 16.4299 27.8965 16.5413 27.8461C16.7557 27.7496 16.9701 27.6553 17.1824 27.5589C17.6112 27.3681 18.0379 27.1774 18.4668 26.9866L21.0334 25.84C22.7445 25.077 24.4578 24.3119 26.1689 23.5489C27.88 22.7859 29.5932 22.0208 31.3043 21.2578C33.0154 20.4948 34.7286 19.7297 36.4397 18.9667C38.1508 18.2038 39.864 17.4386 41.5751 16.6756C41.9556 16.5046 42.3676 16.2503 42.7754 16.1758C43.118 16.1122 43.4522 15.9894 43.797 15.9214C44.4507 15.7921 45.1717 15.7395 45.7982 16.0223C46.0147 16.121 46.2144 16.2591 46.3805 16.4323C47.175 17.2523 47.0636 18.5984 46.8955 19.7516C45.7246 27.789 44.5537 35.8287 43.3808 43.8661C43.221 44.9689 43.0024 46.1791 42.1679 46.8763C41.4616 47.4661 40.4567 47.5319 39.5844 47.2819C38.712 47.0298 37.9427 46.5014 37.188 45.9818C34.058 43.8201 30.9259 41.6583 27.7959 39.4966C27.0517 38.9836 26.2235 38.3127 26.2319 37.3809C26.2361 36.8197 26.5577 36.3198 26.8857 35.8747C29.6058 32.1739 33.5304 29.6307 36.4502 26.1009C36.8622 25.6032 37.1859 24.7043 36.6205 24.4171C36.2841 24.2461 35.8973 24.4785 35.5904 24.6999C31.731 27.4953 27.8737 30.2928 24.0142 33.0881C22.7551 34.0002 21.435 34.9386 19.9172 35.1622C18.5593 35.3639 17.1929 34.9692 15.8791 34.5658C14.7776 34.2282 13.6782 33.8818 12.583 33.5244C12.0007 33.3359 11.3995 33.132 10.9497 32.7045C10.4998 32.2769 10.2413 31.5578 10.5125 30.9878C10.6828 30.6304 11.0128 30.4046 11.3596 30.2204L11.3638 30.2226Z"
                    fill="black"
                  />
                </svg>
              </a>
              <a>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="63"
                  height="63"
                  viewBox="0 0 60 63"
                  fill="none"
                >
                  <path
                    d="M30.0683 0H29.9317C13.4009 0 0 13.9768 0 31.218V31.3605C0 48.6017 13.4009 62.5784 29.9317 62.5784H30.0683C46.5991 62.5784 60 48.6017 60 31.3605V31.218C60 13.9768 46.5991 0 30.0683 0Z"
                    fill="white"
                  />
                  <path
                    d="M45.0141 16.9233C42.252 15.6013 39.2923 14.6279 36.1959 14.071C36.1391 14.06 36.0823 14.0863 36.055 14.1411C35.6745 14.8471 35.252 15.7701 34.9577 16.4936C31.628 15.974 28.3151 15.974 25.0527 16.4936C24.7563 15.7526 24.319 14.8471 23.9365 14.1411C23.907 14.0885 23.8503 14.0622 23.7956 14.071C20.7014 14.6279 17.7395 15.6013 14.9774 16.9233C14.9542 16.9343 14.9332 16.9518 14.9185 16.976C9.30171 25.7281 7.76294 34.2654 8.51759 42.6975C8.5218 42.7392 8.54282 42.7786 8.57435 42.8027C12.2803 45.6419 15.8708 47.3652 19.3939 48.5074C19.4506 48.525 19.5095 48.5031 19.5452 48.4548C20.3776 47.2687 21.1218 46.0168 21.7587 44.6992C21.7966 44.6225 21.7587 44.5304 21.683 44.4997C20.5037 44.0327 19.3833 43.4649 18.3028 42.8203C18.2167 42.7677 18.2104 42.6405 18.2882 42.5791C18.5152 42.4015 18.7422 42.2174 18.9587 42.031C18.9987 41.9959 19.0533 41.9894 19.0995 42.0113C26.1899 45.3876 33.8668 45.3876 40.873 42.0113C40.9193 41.9872 40.9739 41.9959 41.0139 42.0288C41.2304 42.2152 41.4574 42.4015 41.6865 42.5791C41.7643 42.6405 41.7601 42.7677 41.6739 42.8203C40.5956 43.478 39.473 44.0349 38.2917 44.4997C38.2139 44.5304 38.1802 44.6225 38.2181 44.7014C38.8697 46.0147 39.6118 47.2665 40.4295 48.4548C40.4631 48.5052 40.5241 48.5272 40.5808 48.5096C44.1207 47.3674 47.7111 45.6441 51.4171 42.8049C51.4486 42.7808 51.4697 42.7413 51.4739 42.7019C52.3778 32.9544 49.9604 24.4872 45.0688 16.9803C45.0562 16.9562 45.0373 16.9365 45.012 16.9255L45.0141 16.9233ZM22.8139 37.5628C20.6782 37.5628 18.9209 35.5195 18.9209 33.0092C18.9209 30.4988 20.6467 28.4555 22.8139 28.4555C24.9812 28.4555 26.7427 30.5186 26.707 33.0092C26.707 35.5195 24.9812 37.5628 22.8139 37.5628ZM37.2111 37.5628C35.0754 37.5628 33.3181 35.5195 33.3181 33.0092C33.3181 30.4988 35.0439 28.4555 37.2111 28.4555C39.3784 28.4555 41.14 30.5186 41.1042 33.0092C41.1042 35.5195 39.3973 37.5628 37.2111 37.5628Z"
                    fill="black"
                  />
                </svg>
              </a>
            </div>
            <p className="max-w-4xl mx-auto">A new
              meme created exclusively for the Solana community!!! $CTBUD $CTBUD
              $CTBUD
            </p>
          </div>
        </section>

        <section className="py-10" id="about">
          <div className="container mx-auto px-2 flex flex-col-reverse lg:flex-row items-center justify-between gap-10 bg-about py-3 lg:py-8 lg:px-10 rounded-3xl border-[12px] border-[#E76BA1]">
            <div
              className="text-center lg:text-left max-w-xl"
              data-aos="fade-right"
            >
              <h1 className="text-6xl">Cotton Bud On Solana</h1>
              <p className="mt-6 mb-8 ">With
                humor and originality, $CTBUD brings not only joy and laughter
                but also endless creativity to the meme coin loving community.
              </p>
              <a className="inline-flex py-3 px-8 bg-[#E76BA1] text-white rounded-3xl box-shadow-1 transition-all hover:opacity-75">
                BUY NOW
              </a>
            </div>
            <div className="max-w-sm" data-aos="fade-left">
              <img src={about} alt="" />
            </div>
          </div>
        </section>

        <section id="tokenomics" className="pt-20 pb-10" data-aos="fade-up">
          <div className="container mx-auto px-2 lg:px-5 text-center">
            <h3 className="text-5xl">TOKENOMICS</h3>
            <p className="max-w-xl mx-auto mt-5">
              $CTBUD Contract has completed revoke mint, Liquidity burnt and no
              taxes included.
            </p>
            <div className="mt-16 flex flex-col lg:flex-row justify-center items-center gap-10 lg:gap-20">
              <div className="w-[224px] h-[382px] bg-[url('./img/tokenomics.png')] bg-contain bg-no-repeat text-black flex flex-col justify-center items-center text-center">
                <h4>Supply</h4>
                <span>1B</span>
              </div>
              <div className="w-[224px] h-[382px] bg-[url('./img/tokenomics.png')] bg-contain bg-no-repeat text-black flex flex-col justify-center items-center text-center">
                <h4>Tax</h4>
                <span>0/0</span>
              </div>
              <div className="w-[224px] h-[382px] bg-[url('./img/tokenomics.png')] bg-contain bg-no-repeat text-black flex flex-col justify-center items-center text-center">
                <h4>Liquidity</h4>
                <span>Burned</span>
              </div>
            </div>

            <div className="max-w-[260px] mx-auto mt-20">
              <img src={intro} alt="" />
            </div>

            <div className="flex items-center justify-center gap-5 lg:gap-10 my-10">
              <a className="transition-all hover:opacity-75">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="63"
                  height="63"
                  viewBox="0 0 60 63"
                  fill="none"
                >
                  <path
                    d="M30.0683 0H29.9317C13.4009 0 0 13.9768 0 31.218V31.3605C0 48.6017 13.4009 62.5784 29.9317 62.5784H30.0683C46.5991 62.5784 60 48.6017 60 31.3605V31.218C60 13.9768 46.5991 0 30.0683 0Z"
                    fill="white"
                  />
                  <path
                    d="M39.2841 12.7314H20.7184C15.5893 12.7314 11.4166 17.0834 11.4166 22.4329V40.1477C11.4166 45.4973 15.5893 49.8492 20.7184 49.8492H39.2841C44.4132 49.8492 48.5858 45.4973 48.5858 40.1477V22.4329C48.5858 17.0834 44.4132 12.7314 39.2841 12.7314ZM14.698 22.4329C14.698 18.9711 17.3992 16.1538 20.7184 16.1538H39.2841C42.6033 16.1538 45.3044 18.9711 45.3044 22.4329V40.1477C45.3044 43.6096 42.6033 46.4268 39.2841 46.4268H20.7184C17.3992 46.4268 14.698 43.6096 14.698 40.1477V22.4329Z"
                    fill="black"
                  />
                  <path
                    d="M30.0013 40.3122C34.771 40.3122 38.6535 36.265 38.6535 31.2882C38.6535 26.3114 34.7731 22.2642 30.0013 22.2642C25.2296 22.2642 21.3491 26.3114 21.3491 31.2882C21.3491 36.265 25.2296 40.3122 30.0013 40.3122ZM30.0013 25.6887C32.9632 25.6887 35.3722 28.2012 35.3722 31.2904C35.3722 34.3795 32.9632 36.892 30.0013 36.892C27.0395 36.892 24.6305 34.3795 24.6305 31.2904C24.6305 28.2012 27.0395 25.6887 30.0013 25.6887Z"
                    fill="black"
                  />
                  <path
                    d="M39.4545 23.7221C40.7389 23.7221 41.7857 22.6324 41.7857 21.2907C41.7857 19.9489 40.741 18.8593 39.4545 18.8593C38.168 18.8593 37.1233 19.9489 37.1233 21.2907C37.1233 22.6324 38.168 23.7221 39.4545 23.7221Z"
                    fill="black"
                  />
                </svg>
              </a>
              <a
                href="https://twitter.com/CottonBud_SOL"
                target="_blank"
                rel="noopener noreferrer"
                className="transition-all hover:opacity-75"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="63"
                  height="63"
                  viewBox="0 0 60 63"
                  fill="none"
                >
                  <path
                    d="M59.9958 31.2892C59.9958 47.0917 48.7647 60.1559 34.1746 62.2759C32.8104 62.4732 31.4147 62.5762 29.9979 62.5762C28.3625 62.5762 26.7566 62.4403 25.1927 62.1772C10.9095 59.7788 0 46.8615 0 31.2892C0 14.0091 13.4319 0 30 0C46.5681 0 60 14.0091 60 31.2892H59.9958Z"
                    fill="white"
                  />
                  <path
                    d="M12.1664 13.8008L26.0019 33.0935L12.0802 48.7798H15.2143L27.4039 35.0469L37.2519 48.7798H47.9154L33.3022 28.4019L46.2611 13.8008H43.127L31.9022 26.4485L22.832 13.8008H12.1685H12.1664ZM16.774 16.208H21.6717L43.3036 46.3726H38.4059L16.774 16.208Z"
                    fill="black"
                  />
                </svg>
              </a>
              <a
                href="https://t.me/CottonBud_Sol"
                target="_blank"
                rel="noopener noreferrer"
                className="transition-all hover:opacity-75"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="63"
                  height="63"
                  viewBox="0 0 60 63"
                  fill="none"
                >
                  <path
                    d="M30.0683 0H29.9317C13.4009 0 0 13.9768 0 31.218V31.3605C0 48.6017 13.4009 62.5784 29.9317 62.5784H30.0683C46.5991 62.5784 60 48.6017 60 31.3605V31.218C60 13.9768 46.5991 0 30.0683 0Z"
                    fill="white"
                  />
                  <path
                    d="M11.3638 30.2226C11.4479 30.1788 11.532 30.1371 11.614 30.0977C13.0392 29.4093 14.4833 28.7647 15.9254 28.1201C16.0031 28.1201 16.1334 28.0258 16.207 27.9951C16.3184 27.9447 16.4299 27.8965 16.5413 27.8461C16.7557 27.7496 16.9701 27.6553 17.1824 27.5589C17.6112 27.3681 18.0379 27.1774 18.4668 26.9866L21.0334 25.84C22.7445 25.077 24.4578 24.3119 26.1689 23.5489C27.88 22.7859 29.5932 22.0208 31.3043 21.2578C33.0154 20.4948 34.7286 19.7297 36.4397 18.9667C38.1508 18.2038 39.864 17.4386 41.5751 16.6756C41.9556 16.5046 42.3676 16.2503 42.7754 16.1758C43.118 16.1122 43.4522 15.9894 43.797 15.9214C44.4507 15.7921 45.1717 15.7395 45.7982 16.0223C46.0147 16.121 46.2144 16.2591 46.3805 16.4323C47.175 17.2523 47.0636 18.5984 46.8955 19.7516C45.7246 27.789 44.5537 35.8287 43.3808 43.8661C43.221 44.9689 43.0024 46.1791 42.1679 46.8763C41.4616 47.4661 40.4567 47.5319 39.5844 47.2819C38.712 47.0298 37.9427 46.5014 37.188 45.9818C34.058 43.8201 30.9259 41.6583 27.7959 39.4966C27.0517 38.9836 26.2235 38.3127 26.2319 37.3809C26.2361 36.8197 26.5577 36.3198 26.8857 35.8747C29.6058 32.1739 33.5304 29.6307 36.4502 26.1009C36.8622 25.6032 37.1859 24.7043 36.6205 24.4171C36.2841 24.2461 35.8973 24.4785 35.5904 24.6999C31.731 27.4953 27.8737 30.2928 24.0142 33.0881C22.7551 34.0002 21.435 34.9386 19.9172 35.1622C18.5593 35.3639 17.1929 34.9692 15.8791 34.5658C14.7776 34.2282 13.6782 33.8818 12.583 33.5244C12.0007 33.3359 11.3995 33.132 10.9497 32.7045C10.4998 32.2769 10.2413 31.5578 10.5125 30.9878C10.6828 30.6304 11.0128 30.4046 11.3596 30.2204L11.3638 30.2226Z"
                    fill="black"
                  />
                </svg>
              </a>
              <a className="transition-all hover:opacity-75">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="63"
                  height="63"
                  viewBox="0 0 60 63"
                  fill="none"
                >
                  <path
                    d="M30.0683 0H29.9317C13.4009 0 0 13.9768 0 31.218V31.3605C0 48.6017 13.4009 62.5784 29.9317 62.5784H30.0683C46.5991 62.5784 60 48.6017 60 31.3605V31.218C60 13.9768 46.5991 0 30.0683 0Z"
                    fill="white"
                  />
                  <path
                    d="M45.0141 16.9233C42.252 15.6013 39.2923 14.6279 36.1959 14.071C36.1391 14.06 36.0823 14.0863 36.055 14.1411C35.6745 14.8471 35.252 15.7701 34.9577 16.4936C31.628 15.974 28.3151 15.974 25.0527 16.4936C24.7563 15.7526 24.319 14.8471 23.9365 14.1411C23.907 14.0885 23.8503 14.0622 23.7956 14.071C20.7014 14.6279 17.7395 15.6013 14.9774 16.9233C14.9542 16.9343 14.9332 16.9518 14.9185 16.976C9.30171 25.7281 7.76294 34.2654 8.51759 42.6975C8.5218 42.7392 8.54282 42.7786 8.57435 42.8027C12.2803 45.6419 15.8708 47.3652 19.3939 48.5074C19.4506 48.525 19.5095 48.5031 19.5452 48.4548C20.3776 47.2687 21.1218 46.0168 21.7587 44.6992C21.7966 44.6225 21.7587 44.5304 21.683 44.4997C20.5037 44.0327 19.3833 43.4649 18.3028 42.8203C18.2167 42.7677 18.2104 42.6405 18.2882 42.5791C18.5152 42.4015 18.7422 42.2174 18.9587 42.031C18.9987 41.9959 19.0533 41.9894 19.0995 42.0113C26.1899 45.3876 33.8668 45.3876 40.873 42.0113C40.9193 41.9872 40.9739 41.9959 41.0139 42.0288C41.2304 42.2152 41.4574 42.4015 41.6865 42.5791C41.7643 42.6405 41.7601 42.7677 41.6739 42.8203C40.5956 43.478 39.473 44.0349 38.2917 44.4997C38.2139 44.5304 38.1802 44.6225 38.2181 44.7014C38.8697 46.0147 39.6118 47.2665 40.4295 48.4548C40.4631 48.5052 40.5241 48.5272 40.5808 48.5096C44.1207 47.3674 47.7111 45.6441 51.4171 42.8049C51.4486 42.7808 51.4697 42.7413 51.4739 42.7019C52.3778 32.9544 49.9604 24.4872 45.0688 16.9803C45.0562 16.9562 45.0373 16.9365 45.012 16.9255L45.0141 16.9233ZM22.8139 37.5628C20.6782 37.5628 18.9209 35.5195 18.9209 33.0092C18.9209 30.4988 20.6467 28.4555 22.8139 28.4555C24.9812 28.4555 26.7427 30.5186 26.707 33.0092C26.707 35.5195 24.9812 37.5628 22.8139 37.5628ZM37.2111 37.5628C35.0754 37.5628 33.3181 35.5195 33.3181 33.0092C33.3181 30.4988 35.0439 28.4555 37.2111 28.4555C39.3784 28.4555 41.14 30.5186 41.1042 33.0092C41.1042 35.5195 39.3973 37.5628 37.2111 37.5628Z"
                    fill="black"
                  />
                </svg>
              </a>
            </div>
          </div>
        </section>

        <section>
          <div className="container mx-auto px-2 lg:px-5 py-10 border-t-[1px] border-t-white text-center">
            <span>Copyright Cotton Bud - All rights reserved</span>
          </div>
        </section>
      </main>
    </div>
  );
}

export default App;
